@import './helpers/_colors.scss';
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #585a61;
}

a {
  color: $primaryColor;
}

a:hover {
  color: #7b94c9;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

.error-message {
  color: $whiteColor;
  background: #ed3c0d;
  text-align: left;
  font-weight: 600;
}

.success {
  color: $whiteColor;
  background: #18d26e;
  text-align: center;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/

label {
  font-family: "Poppins", sans-serif;
  color: #757680;
}

input, textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

button[type="submit"] {
  background: $whiteColor;
  border: 2px solid $primaryColor;
  color: $primaryColor;
  transition: 0.4s;
  border-radius: 50px;

  &:hover {
    background: $primaryColor;
    color: $whiteColor;
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  right: 15px;
  bottom: 15px;
  background: $primaryColor;
  color: $whiteColor;
  transition: display 0.5s ease-in-out;
  z-index: 99999;
}

.back-to-top i {
  font-size: 24px;
  position: absolute;
  top: 7px;
  left: 8px;
}

.back-to-top:hover {
  color: $whiteColor;
  background: #748ec6;
  transition: background 0.2s ease-in-out;
}

#main {
  margin-top: 75px;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: $background;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: $primaryColor;
  font-family: "Poppins", sans-serif;
}

.section-title p {
  margin-bottom: 0;
}