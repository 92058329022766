@import "../assets/scss/helpers/colors";

#header {
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.95);

  &.header-transparent {
    background: transparent;
  }

  &.header-scrolled {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }

  .logo {
    h1 {
      font-size: 30px;
      margin: 0;
      padding: 6px 0;
      line-height: 1;
      font-weight: 400;
      letter-spacing: 2px;

      a,
      a:hover {
        color: #00B3D4;
        text-decoration: none;
      }
    }

    img {
      padding: 6px 0;
      margin: 0;
      max-height: 80px;
    }
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
  }

  a {
    display: block;
    position: relative;
    color: $text;
    padding: 12px 15px;
    transition: 0.3s;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }

  a:hover,
  .active > a,
  li:hover > a {
    color: #00B3D4;
    text-decoration: none;
  }

  .get-started a {
    background: #00B3D4;
    color: #fff;
    border-radius: 50px;
    margin: 0 15px;
    padding: 10px 25px;

    &:hover {
      background: #00B3D4;
      color: #fff;
    }
  }

  .drop-down {
    ul {
      display: block;
      position: absolute;
      left: 0;
      top: calc(100% - 30px);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      padding: 10px 0;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: ease all 0.3s;

      a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: #233355;

        &:after {
          content: "\ea99";
          font-family: IcoFont;
          padding-left: 5px;
        }
      }

      a:hover,
      .active > a,
      li:hover > a {
        color: #00B3D4;
      }
    }

    &:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

    li {
      min-width: 180px;
      position: relative;
    }

    .drop-down {
      ul {
        top: 0;
        left: calc(100% - 30px);
      }

      &:hover > ul {
        opacity: 1;
        top: 0;
        left: 100%;
      }

      > a {
        padding-right: 35px;

        &:after {
          content: "\eaa0";
          font-family: IcoFont;
          position: absolute;
          right: 15px;
        }
      }

      @media (max-width: 1366px) {
        ul {
          left: -90%;
        }
        &:hover > ul {
          left: -100%;
        }
        > a:after {
          content: "\ea9d";
        }
      }
    }
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 22px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;

  i {
    color: $primaryColor;
  }
}

.mobile-nav-toggle i {
  color: $primaryColor;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;

  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    position: relative;
    color: $text;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
  }

  a:hover,
  &.active > a,
  li:hover > a {
    color: $primaryColor;
    text-decoration: none;
  }

  .drop-down {
    > a:after {
      content: "\ea99";
      font-family: IcoFont;
      padding-left: 10px;
      position: absolute;
      right: 15px;
    }

    > a {
      padding-right: 35px;
    }

    ul {
      display: none;
      overflow: hidden;
    }

    li {
      padding-left: 20px;
    }

    &.active > a:after {
      content: "\eaa1";
    }
  }
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(35, 51, 85, 0.7);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;

  .mobile-nav {
    opacity: 1;
    visibility: visible;
  }

  .mobile-nav-toggle i {
    color: #fff;
  }
}
